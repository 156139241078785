.credit-card {
  margin-bottom: 3rem;
  margin-top: 1rem;
  padding: 0 7rem;
  .payment-input {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border-width: 1px;
    margin-bottom: 10px;
    padding: 10px;
  }

  .payment-valid {
    width: calc(50% - 10px);
    height: 40px;
    border-radius: 5px;
    border-width: 1px;
    padding: 10px;
    margin-bottom: 10px;
  }
  .payment-left {
    margin-right: 20px;
  }
  .flex {
     display: flex;
  }

  .error {
    border-color: red;
  }

  @media screen and (max-width : 768px) {
      padding: 0 0.2rem;
      .flex {
        flex-direction: column;
      }
      .payment-valid { 
        width: 100%;
      }
  }
}
