.fade-enter {
    opacity: 0;
    z-index: 1;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 100ms ease-in;
}

.child-animation {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.react-autosuggest__container {
    position: relative;
    font-family: MR;
    width: 423px;
    height: 69px;
    margin: auto;
    font-weight: normal;
    text-align: center;
    font-size: 31px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.55);
}

.react-autosuggest__input {
    width: 240px;
    height: 30px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
}
.react-autosuggest__container input:focus,
.react-autosuggest__container input:active {
    outline: #cccccc !important;
    border: 2px solid #cccccc !important;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
    z-index: 3;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    bottom: 68px;
    // width: 696px;
    width: 100%;
    // left: -136.5px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 3;
    max-height: 700px;
    overflow: auto;
    // border-radius: 16px;
    border-radius: 0;
    box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 769px) {
    .start, .search-menu {
        .react-autosuggest__suggestions-container--open {
            left: 50%;
            transform: translate(-49%, 0%);
        }
        .react-autosuggest__container {
            height: 40px;
            font-size: 24px;
            line-height: 25px;
        }
    }
    .search-menu .react-autosuggest__suggestions-container--open {
        width: 84vw;
        bottom: 44px;
        transform: translate(-50%, 0%);
    }
    .react-autosuggest__suggestions-container--open {
        bottom: 64px;
        width: 94vw;
        left: 2px;
        max-height: 400px;
        transition: all .25s ease;
    }
    .react-autosuggest__suggestion {
        cursor: pointer;
        padding: 0 0px !important;
        .suggest-text {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            .suggest-name {
                font-size: 16px;
            }
            .suggest-phone {
                font-size: 15px;
            }
        }
    }
    .suggest-name {
        line-height: 20px;
        font-size: 14px !important;
        p {
            max-width: 100px !important;
            line-height: 18px;
            font-size: 14px;
        }
        .start-name {
            max-width: 200px !important;
        }
    }
    .suggest-text {
        height: unset !important;
        padding: 5px 5px !important;
        .info-box {
            flex: unset;
        }
        p.p2 {
            margin-bottom: auto !important;
        }
        img {
            height: 100%;
            width: 69px;
        }
    }
}

.suggest-text {
    // font-family: MBold;
    font-size: 22px;
    text-align: left;
    display: flex;
    padding: 5px 12px;
    height: 100px;
    border-bottom: 1px solid #dfdfdf;
    img {
        width: 69px;
    }
    .info-box {
        flex: 1;
        margin: auto;
        padding-left: 10px;
    }
    p.p2 {
        margin: auto;
        font-family: Mbold;
        font-style: normal;
        // font-weight: 900;
        font-size: 36px;
        display: block;
        line-height: 32px;
        /* identical to box height, or 80% */
        margin-left: 0;
        text-align: center;

        color: #2d2725;
        sub {
            font-size: 36px;
        }
        sup {
            font-family: MBold;
            font-weight: 500;
            font-size: 18px;
            line-height: 32px;
            /* identical to box height, or 160% */
            margin-left: 3px;
            letter-spacing: 0.1px;
            text-align: center;
            top: -0.5em;
            color: #2d2725;
        }
        span {
            font-family: GI;
            font-style: italic;
            font-weight: normal;
            font-size: 18px;
            line-height: 32px;
            padding-right: 10px;
            /* or 178% */

            color: #2d2725;
        }
    }
}

.suggest-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #786960;
    font-size: 21px;
    line-height: 32px;
    font-family: MM;
    display: flex;

    p {
        max-width: 320px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin: 0;
        line-height: 32px;
    }
}

.suggest-phone {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre-wrap;
    color: black;
    font-size: 24px;
    line-height: 32px;
    font-family: MBold;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 0 0px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd !important;
}

.page {
    opacity: 1;
}

.pageSlider-exit .page {
    z-index: 1;
}
//new page entering, show it on top
.pageSlider-exit.left .page {
    z-index: 2;
}
.pageSlider-enter.pageSlider-enter-active .page {
    opacity: 1;
    transition: opacity 500ms ease-in;
}
.pageSlider-enter-done .thumb-vertical,
.pageSlider-exit-done .thumb-vertical {
    opacity: 1;
    transition: opacity 400ms;
}
.pageSlider-enter-done .content,
.pageSlider-exit-done .content {
    opacity: 1;
}
//LEFT transition transform 100% to the right
.pageSlider-enter.left > .page {
    transform: translate3d(100%, 0, 0);
}
//RIGHT transition transform -100% to the left
.pageSlider-enter.right > .page {
    transform: translate3d(-100%, 0, 0);
}
//old exit left direction
.pageSlider-exit.left > .page {
    transform: translate3d(-100%, 0, 0);
    // opacity: 0;
    transition: opacity 500ms;
}

//old exit right direction
.pageSlider-exit.right > .page {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
    transition: opacity 500ms;
}
//Enter is active (Page has finished transitioning) make it settle down
.pageSlider-enter.pageSlider-enter-active > .page {
    transform: translate3d(0, 0, 0);
    //Apply all transitions
    transition: all 600ms ease-in-out !important;
}

//new page entering, show it on top
.pageSideFoodSlider-exit.left .page {
    z-index: 2;
}
.pageSideFoodSlider-enter.pageSideFoodSlider-enter-active .page {
    opacity: 1;
    transition: opacity 500ms ease-in;
}
.pageSideFoodSlider-enter .thumb-vertical,
.pageSideFoodSlider-exit .thumb-vertical {
    opacity: 0;
}
.pageSideFoodSlider-enter-done .thumb-vertical,
.pageSideFoodSlider-exit-done .thumb-vertical {
    opacity: 1;
    transition: opacity 400ms;
}
.pageSideFoodSlider-enter-done .content,
.pageSideFoodSlider-exit-done .content {
    opacity: 1;
}
//LEFT transition transform 100% to the right
.pageSideFoodSlider-enter.left > .page {
    transform: translate3d(100%, 0, 0);
}
//RIGHT transition transform -100% to the left
.pageSideFoodSlider-enter.right > .page {
    transform: translate3d(-100%, 0, 0);
}
//old exit left direction
.pageSideFoodSlider-exit.left > .page {
    transform: translate3d(-100%, 0, 0);
    opacity: 0;
    transition: opacity 500ms;
}
.pageSideFoodSlider-exit-active.left > .page {
    opacity: 0;
}
//old exit right direction
.pageSideFoodSlider-exit.right > .page {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
    transition: opacity 500ms;
}
//Enter is active (Page has finished transitioning) make it settle down
.pageSideFoodSlider-enter.pageSideFoodSlider-enter-active > .page {
    transform: translate3d(0, 0, 0);
    //Apply all transitions
    transition: all 600ms ease-in-out !important;
}

.track-vertical {
    background: #7D7062;
    border-radius: 20px;
    margin-bottom: 10px;
    height: 98% !important;
    right: 19px !important;
}

.thumb-vertical {
    background: #463F36;
    padding: 0 5px;
    border-radius: 30px !important;
    margin-left: -2px;
}

.custom-thumb {
    width: 50px;
    position: absolute;
    top: calc(50% - 25px);
    right: -17px;
}

.my-custom-scrollbar > div {
    margin-top: 15px;
}

.test {
    margin: 20px;
    padding: 2px;
    width: 500px;
    height: 800px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: red;
}

.my-node-enter {
    // opacity: 0;
    color: yellowgreen;
}
.my-node-enter-active {
    // opacity: 1;
    // transition: opacity 200ms;
    transform: translate3d(23%, 0, 0);
}
.my-node-exit {
    // opacity: 1;
    transform: translate3d(-100%, 0, 0);
}
.my-node-exit-active {
    // opacity: 0;
    color: red;
    transition: opacity 200ms;
}

.my-node-exit-done {
    color: red;
}

.scroll-to-top {
    transition: all 0.25s ease-in-out;
    position: fixed;
    bottom: 230px;
    right: 60px;
    display: inline-flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    margin: 0 3em 3em 0;
    padding: 0.25em;
    width: 70px;
    height: 70px;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 10;
    img {
        width: 60px;
    }
}

.menu-transaction-enter-done {
    transform: translateY(0);
    opacity: 1 !important;
}

.animated-carousel {
    transform: translateY(60px);
    opacity: 0;
    transition: 1s all ease;
}

.animated-carousel-inactive {
    transform: translateY(0);
    opacity: 1;
}

@media only screen and (max-width: 769px) {
    .scroll-to-top {
        width: 40px;
        height: 40px;
        bottom: 50px;
        right: 0;
        transform: translate3d(0, 0, 0);
        border-radius: 6px;
        img {
            width: 30px;
        }
    }
}

.switch-toggle, .switch-toggle-nori {
    display: inline-block;
    height: 22px;
    position: relative;
    width: 48px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin: 0 auto;
}
  
.switch-toggle input, .switch-toggle-nori input {
    display:none;
}
  
.slider {
    background-color: #ccc;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
}
  
.slider:before {
    background-color: #fff;
    bottom: 4px;
    content: "";
    height: 14px;
    width: 14px;
    left: 5px;
    position: absolute;
    transition: .4s;
}
  
input:checked + .slider {
    background-color: #66bb6a;
}
  
input:checked + .slider:before {
    transform: translateX(22px);
}
  
.slider.round {
    border-radius: 34px;
}
  
.slider.round:before {
    border-radius: 50%;
}